<template>
  <div
    style="background-color:var(--color-beige); color:var(--color-braun);"
  >
    <div class="bg bg-repeat-x h-10 lg:h-16" />
    <div class="max-w-5xl mx-auto px-5 lg:px-10 py-10 lg:py-32 text-center tracking-widest leading-8">
      <div class="my-10 mx-auto grid w-2/6">
        <div class="flex flex-col items-center">
          <RouterLink
            class="opacity-30 mb-5 w-4/6"
            to="/"
          >
            <img
              class="w-full"
              src="@/assets/Grafiken/Genuss-Cafe-Icon-Braun.svg"
            >
          </RouterLink>
          <RouterLink
            to="/"
          >
            <img
              class="w-full"
              src="@/assets/Grafiken/Genuss-Cafe-Logo-Braun.svg"
            >
          </RouterLink>
        </div>
      </div>
      <RouterLink
        class="relative inline-block text-sm font-medium group focus:outline-none focus:ring uppercase mb-10"
        style="color:var(--color-braun);"
        to="/"
      >
        <span class="absolute inset-0 border border-current" />
        <span class="block px-12 py-3 border transition-transform border-current group-hover:-translate-x-1 group-hover:-translate-y-1">
          Startseite
        </span>
      </RouterLink>
      <div class="text-5xl md:text-8xl font-bold mb-10 font-['Boska']">
        Datenschutz
      </div>
      <div class="text-left">
        <div>
          <p>&nbsp;</p>
          <p>
            <strong>Datenschutzmitteilung</strong>
          </p>
          <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (österreichisches Datenschutzgesetz, DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Welche Daten wir über Sie verarbeiten</strong>
          </p>
          <p>Im Zuge Ihres Besuches dieser Website werden wir folgende Informationen erheben: Das Datum und die Uhrzeit des Aufrufs einer Seite auf unserer Website, Ihre IP-Adresse, Name und Version Ihres Web-Browsers, die Website (URL), die Sie vor dem Aufruf dieser Seite besucht haben.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Zwecke der Datenverarbeitung</strong>
          </p>
          <p>Wir werden Ihre personenbezogenen Daten zu folgenden Zwecken verarbeiten:</p>
          <ul>
            <li>um Ihnen diese Website zur Verfügung zu stellen und um diese Website weiter zu verbessern und zu entwickeln:</li>
            <li>um Nutzungsstatistiken erstellen zu können;</li>
            <li>um Angriffe auf unsere Website erkennen, verhindern und untersuchen zu können;</li>
            <li>um auf Ihre Anfragen antworten zu können;</li>
          </ul>
          <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Rechtsgrundlagen der Verarbeitung</strong>
          </p>
          <p>Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen Daten ist unser überwiegendes berechtigtes Interesse (gemäß Art 6 Abs 1 lit f DSGVO), welches darin besteht, die oben genannten Zwecke zu erreichen.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Übermittlung Ihrer personenbezogenen Daten</strong>
          </p>
          <p>Zu den oben genannten Zwecken werden wir Ihre personenbezogenen Daten an folgende Empfänger übermitteln:</p>
          <ul>
            <li>von uns eingesetzte IT-Dienstleister; Im Fall von Google-Analytics werden Ihre Daten anonymisiert und anschließend ins Ausland übertragen.</li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>Dauer der Speicherung</strong>
          </p>
          <p>Wir werden Ihre Daten grundsätzlich für eine Dauer von drei Monaten speichern. Eine längere Speicherung erfolgt nur, soweit dies erforderlich ist, um festgestellte Angriffe auf unsere Website zu untersuchen.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.</p>
          <p>Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
          <p>Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.</p>
          <p>Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>
          <p>&nbsp;</p>
          <p>
            <strong>Web-Analyse</strong>
          </p>
          <p>Unsere Webseite verwendet Google Analytics. Dies ist ein Dienst zur Analyse von Internetseiten der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Ihre Daten werden an einen Google-Server in den USA übertragen. Dazu werden Cookies verwendet, die eine Analyse der Benutzung der Website durch Ihre Benutzer ermöglicht. Die dadurch erzeugten Informationen werden auf den Server des Anbieters übertragen und dort gespeichert. Wir speichern keine Ihrer Daten, die in Zusammenhang mit der Nutzung des Dienstes erhoben werden.</p>
          <p>Sie können dies verhindern, indem Sie Ihren Browser so einrichten, dass keine Cookies gespeichert werden.</p>
          <p>Wir haben mit dem Anbieter einen entsprechenden Vertrag zur Auftragsdatenverarbeitung abgeschlossen.</p>
          <p>Ihre IP-Adresse wird erfasst, aber umgehend &nbsp;anonymisiert. Dadurch ist nur mehr eine grobe Lokalisierung möglich.</p>
          <p>Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.</p>
          <p>Die im Rahmen des Dienstes von Ihrem Browser übermittelte IP-Adresse wird von Google nicht mit anderen Daten zusammengeführt.</p>
          <p>Zur Verhinderung der Speicherung von Cookies verweisen wir auf das vorstehende Kapitel „Cookies“. Sie können darüber hinaus verhindern, dass Google Ihre Daten in Zusammenhang mit Google Analytics erhebt, indem Sie das unter dem folgenden Link verfügbare Browser Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a>. </p>
          <p>Sie können die Erhebung Ihrer Daten durch Google Analytics auf dieser Website auch verhindern, indem Sie auf <a href="javascript:gaOptout();">diesen Link</a> klicken. Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch der Website verhindert: </p>
          <p>
            Für weiterführende Informationen zu Google Analytics klicken Sie <a
              href="https://policies.google.com/privacy?hl=de"
              target="_blank"
            >hier</a>.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Ihre Rechte im Zusammenhang mit personenbezogenen Daten</strong>
          </p>
          <p>Sie sind unter anderem berechtigt (unter Voraussetzungen anwendbaren Rechts):</p>
          <ul>
            <li>zu überprüfen, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien dieser Daten zu erhalten;</li>
            <li>die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen;</li>
            <li>von uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken;</li>
            <li>unter bestimmten Umständen der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen oder die für das Verarbeiten zuvor gegebene Einwilligung zu widerrufen;</li>
            <li>Datenübertragbarkeit zu verlangen</li>
            <li>sowie die Identität von Dritten, an welche Ihre personenbezogenen Daten übermittelt werden, zu kennen und</li>
            <li>bei der zuständigen Behörde Beschwerde zu erheben.</li>
          </ul>
          <p>&nbsp;</p>
          <p>
            <strong>Sie erreichen uns unter folgenden Kontaktdaten</strong>:
          </p>
          <h2 class="font-bold">
            Cafe Genuss OG
          </h2>
          <p>Mozartplatz 2a<br>5340 St. Gilgen<br>Österreich</p>
          <br>
          <p>
            Telefon: +43 660 41 11 650 <br> E-Mail: <a
              href="mailto:info@genuss-cafe.at"
            >info@genuss-cafe.at</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataPrivacy'
}
</script>
